import React from 'react'

const Speaker = ({ name, role, image }) => {
  return (
    <div className="speaker">

        <div>
          <img src={image} alt={name} className="speaker-image" />
        </div>


        <div className="speaker-details">
            <p className="speaker-name">{name}</p>
            <p className="speaker-role">{role}</p>
        </div>
  </div>
  )
}

export default Speaker