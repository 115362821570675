import React from "react";

import Nav from "./Nav";

import Mobilist from "../images/eventPartners/MOBILIST_logo.png";

const Hero = ({ scrollToSection, refs }) => {
  return (
    <div className="hero">
      <Nav />

      <div className="hero_text_container">

        <div className="hero_text_content">
            <h1>THE ROAD TO IPO 2024</h1>

            <div className="settings">
              <p>October 22, 2024 | 9:00 AM - 2:00 PM</p>
              <p>Makati City</p>
            </div>

            <div className="co-host">
              <p>Co-host:</p>
              <img src={Mobilist} alt="Mobilist" className="mobilist_logo" />
            </div>
            
            <button onClick={() => scrollToSection(refs.formRef)}>Register Now</button>
        </div>

      </div>
    </div>
  );
};

export default Hero;
