import React from 'react'

import Speaker from './Speaker'

const EventList = ({ time, duration, segment, speakers }) => {
  return (
    <div className="schedule-row">
        <div className='schedule-details'>
            <div className="schedule-time">{time}</div>
            {/* <div className="schedule-duration">{duration}</div> */}
            <div className="schedule-segment">{segment}</div>
        </div>
        <div className="schedule-speakers">
            {speakers.length > 0
                ? speakers.map((speaker, index) => (
                    <Speaker
                    key={index}
                    name={speaker.name}
                    role={speaker.role}
                    image={speaker.image}
                    />
                ))
                : ""}
        </div>
  </div>
  )
}

export default EventList