import React, { useState } from "react";

import RegistrationModal from "./RegistrationModal";

const RegistrationForm = () => {
  // Separate state for each form field
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [customIndustry, setCustomIndustry] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [companyAge, setCompanyAge] = useState("");
  const [interestedInIPO, setInterestedInIPO] = useState("");
  const [fundingAmount, setFundingAmount] = useState("");
  const [fundingTimeline, setFundingTimeline] = useState("");
  const [fundingAreas, setFundingAreas] = useState([]);
  const [customFundingArea, setCustomFundingArea] = useState("");
  const [futureIPO, setFutureIPO] = useState("");
  const [otherFundRaising, setOtherFundRaising] = useState("");
  const [customFundRaising, setCustomFundRaising] = useState("");
  const [netIncome, setNetIncome] = useState("");
  const [interestedInIPOAdvisor, setInterestedInIPOAdvisor] = useState("");
  const [ipoAdvisors, setIpoAdvisors] = useState([]);
  const [questions, setQuestions] = useState("");
  const [dataPrivacyAccepted, setDataPrivacyAccepted] = useState(false); // New state for the checkbox
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [emailExists, setEmailExists] = useState(false); // Track if email exists
  const [isTyping, setIsTyping] = useState(false); // Track if user is typing
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // Function to handle email input change
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsTyping(true);

    // Clear the previous timeout if it exists
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to call the email check function after typing stops
    const newTimeout = setTimeout(() => {
      checkIfEmailExists(enteredEmail); // Call function to check email
    }, 500); // 500ms debounce time (adjust if needed)

    setDebounceTimeout(newTimeout); // Save the timeout ID for later clearing
  };

  // Function to check if the email exists (API call)
  const checkIfEmailExists = async (email) => {
    setIsTyping(false); // User has stopped typing
    try {
        const response = await fetch(`https://pse-api.pinion.ph/api/answers/email/${email}`);

        console.log(response)

        const data = await response.json();

        if (response.ok && data.message === "User already exists!") {
            setEmailExists(true); // Set email exists to true
        } else {
            setEmailExists(false); // Email doesn't exist
        }
    } catch (error) {
        console.error("Error checking email:", error);
        setEmailExists(false); // Fallback in case of error
    }
};

  // IPO Advisors options in alphabetical order
  const ipoAdvisorOptions = [
    "BDO Capital & Investment Corporation",
    "China Bank Capital Corporation",
    "First Metro Investment Corporation",
    "Investment & Capital Corporation of the Philippines",
    "PNB Capital and Investment Corporation",
    "SB Capital Investment Corporation",
    "Any of the above advisors",
  ];

  // Handle checkbox change for IPO Advisors
  const handleIPOAdvisorsChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setIpoAdvisors([...ipoAdvisors, value]); // Add to selected advisors
    } else {
      setIpoAdvisors(ipoAdvisors.filter((advisor) => advisor !== value)); // Remove if unchecked
    }
  };

  const handleFundingAreasChange = (e) => {
    const { value, checked } = e.target;
    if (value === "Other") {
      // If "Other" is selected, show the input field
      if (checked) {
        setFundingAreas([...fundingAreas, value]);
      } else {
        setFundingAreas(fundingAreas.filter((area) => area !== value));
        setCustomFundingArea(""); // Clear the custom input if "Other" is deselected
      }
    } else {
      // Handle other checkbox selections
      if (checked) {
        setFundingAreas([...fundingAreas, value]);
      } else {
        setFundingAreas(fundingAreas.filter((area) => area !== value));
      }
    }
  };

  const handleOtherFundRaisingChange = (e) => {
    const { value } = e.target;
    setOtherFundRaising(value);

    if (value === "Other") {
      setCustomFundRaising(""); // Show input field for "Other" and reset its value
    }
  };

  // Validation: Check if all required fields are filled
  const isFormValid = () => {
    return (
      name &&
      company &&
      industry &&
      designation &&
      email &&
      emailExists === false &&
      mobile &&
      companyAge &&
      interestedInIPO &&
      questions &&
      dataPrivacyAccepted &&
      (interestedInIPOAdvisor !== "Yes" || ipoAdvisors.length > 0)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission

    setIsLoading(true); // Set loading to true when submission starts
    setApiResponse(null); // Clear any previous API response
    setShowModal(true);

    const formData = {
      name,
      company,
      industry: industry === "Other" ? customIndustry : industry,
      designation,
      email,
      mobile,
      companyAge,
      interestedInIPO,
      fundingAmount,
      fundingTimeline,
      fundingAreas: fundingAreas.includes("Other")
        ? [
            ...fundingAreas.filter((area) => area !== "Other"),
            customFundingArea,
          ]
        : fundingAreas, // Include customFundingArea if "Other" is selected
      futureIPO,
      otherFundRaising:
        otherFundRaising === "Other" ? customFundRaising : otherFundRaising, // Include customFundRaising if "Other" is selected,
      netIncome,
      interestedInIPOAdviser: interestedInIPOAdvisor,
      ipoAdvisors,
      questions,
      dataPrivacy: dataPrivacyAccepted,
    };

    try {
      const response = await fetch("https://pse-api.pinion.ph/api/answers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Convert form data to JSON format
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Form submitted successfully:", result);
        setApiResponse({
          status: "success",
          message: "Form submitted successfully!",
        });
      } else {
        const result = await response.json();
        console.log(result);
        setApiResponse({
          status: "error",
          message: result.error,
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      setApiResponse({
        status: "error",
        message: "An error occurred while submitting the form.",
      });
    } finally {
      setIsLoading(false); // Set loading to false once the response is received
      setShowModal(true); // Show modal once loading is done
    }
  };

  // Function to close modal, clear response and reset form
  const closeModal = () => {
    setShowModal(false); // Close modal
    setApiResponse(null); // Clear the API response
    resetForm(); // Reset all form fields
  };

  // Function to reset the form to its initial state
  const resetForm = () => {
    setName("");
    setCompany("");
    setIndustry("");
    setDesignation("");
    setEmail("");
    setMobile("");
    setCompanyAge("");
    setInterestedInIPO("");
    setFundingAmount("");
    setFundingTimeline("");
    setFundingAreas([]);
    setFutureIPO("");
    setOtherFundRaising("");
    setNetIncome("");
    setInterestedInIPOAdvisor("");
    setIpoAdvisors([]);
    setQuestions("");
    setDataPrivacyAccepted(false);
  };

  return (
    <div className="reg-form">
      <h2>Event Registration Form</h2>

      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <label>
            <span className="required">*</span>Name:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <label>
            <span className="required">*</span>Company:
          </label>
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />

          <label>
            <span className="required">*</span>Industry:
          </label>
          <select
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Consumer Goods & Services">
              Consumer Goods & Services
            </option>
            <option value="Education">Education</option>
            <option value="Energy/Utilities">Energy/Utilities</option>
            <option value="Finance & Financial Services">
              Finance & Financial Services
            </option>
            <option value="Government">Government</option>
            <option value="Healthcare & Pharmaceutical">
              Healthcare & Pharmaceutical
            </option>
            <option value="Industrial Goods & Services">
              Industrial Goods & Services
            </option>
            <option value="Media & Communications">
              Media & Communications
            </option>
            <option value="Non-Profit Organization">
              Non-Profit Organization
            </option>
            <option value="Professional Services">Professional Services</option>
            <option value="Transportation">Transportation</option>
            <option value="Technology">Technology</option>
            <option value="Other">Other</option>
          </select>

          {industry === "Other" && (
            <div className="please-specify">
              <label>Please specify:</label>
              <input
                type="text"
                value={customIndustry}
                onChange={(e) => setCustomIndustry(e.target.value)}
              />
            </div>
          )}

          <label>
            <span className="required">*</span>Designation:
          </label>
          <input
            type="text"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
          />

          <label>
            <span className="required">*</span>Email Address:
          </label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
          />
          {emailExists && !isTyping && <p className="email-exists">Email already exists!</p>}
          
          <label>
            <span className="required">*</span>Mobile Number:
          </label>
          <input
            type="tel"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />

          <label>
            <span className="required">*</span>How long has your company been in
            operation?
          </label>
          <select
            value={companyAge}
            onChange={(e) => setCompanyAge(e.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Less than a year">Less than a year</option>
            <option value="1 to 2 years">1 to 2 years</option>
            <option value="3 to 5 years">3 to 5 years</option>
            <option value="More than 5 years">More than 5 years</option>
          </select>

          <label>
            <span className="required">*</span>Are you interested to raise
            capital via IPO listing?
          </label>
          <select
            value={interestedInIPO}
            onChange={(e) => setInterestedInIPO(e.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Maybe">Maybe</option>
            <option value="Not Applicable">Not Applicable</option>
          </select>

          {/* Conditionally render based on IPO interest */}
          {(interestedInIPO === "Yes" || interestedInIPO === "Maybe") && (
            <>
              <label>How much funding would you want to raise?</label>
              <select
                value={fundingAmount}
                onChange={(e) => setFundingAmount(e.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="Not exceeding ₱500M">Not exceeding ₱500M</option>
                <option value="Over ₱500M to ₱1B">Over ₱500M to ₱1B</option>
                <option value="Over ₱1B to ₱5B">Over ₱1B to ₱5B</option>
                <option value="Over ₱5B to ₱10B">Over ₱5B to ₱10B</option>
                <option value="Over ₱10B">Over ₱10B</option>
              </select>

              <label>What is your timeline to raise this funding?</label>
              <select
                value={fundingTimeline}
                onChange={(e) => setFundingTimeline(e.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="1 to 2 years">1 to 2 years</option>
                <option value="3 to 5 years">3 to 5 years</option>
                <option value="5 to 10 years">5 to 10 years</option>
                <option value="More than 10 years">More than 10 years</option>
              </select>

              <label className="checkbox-label">
                In what areas will the funding be used?
              </label>
              <div className="checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    value="Expansion"
                    onChange={handleFundingAreasChange}
                    checked={fundingAreas.includes("Expansion")}
                  />{" "}
                  Expansion
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Research & Development"
                    onChange={handleFundingAreasChange}
                    checked={fundingAreas.includes("Research & Development")}
                  />{" "}
                  Research & Development
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Working Capital"
                    onChange={handleFundingAreasChange}
                    checked={fundingAreas.includes("Working Capital")}
                  />{" "}
                  Working Capital
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Debt Repayment"
                    onChange={handleFundingAreasChange}
                    checked={fundingAreas.includes("Debt Repayment")}
                  />{" "}
                  Debt Repayment
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Other"
                    onChange={handleFundingAreasChange}
                    checked={fundingAreas.includes("Other")}
                  />{" "}
                  Other
                </label>

                {/* Conditionally render the custom input for "Other" */}
                {fundingAreas.includes("Other") && (
                  <div className="please-specify">
                    <label>Please specify:</label>
                    <input
                      type="text"
                      value={customFundingArea}
                      onChange={(e) => setCustomFundingArea(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {interestedInIPO === "No" && (
            <>
              <label>Would you consider IPO listing in the future?</label>
              <select
                value={futureIPO}
                onChange={(e) => setFutureIPO(e.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>

              <label>
                What other capital/fund-raising initiatives is your company
                currently pursuing?
              </label>
              <select
                value={otherFundRaising}
                onChange={handleOtherFundRaisingChange}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="Strategic Partnerships">
                  Strategic Partnerships
                </option>
                <option value="Debt Financing">Debt Financing</option>
                <option value="Equity Financing">Equity Financing</option>
                <option value="Crowdfunding Campaign">
                  Crowdfunding Campaign
                </option>
                <option value="Other">Other</option>
              </select>

              {/* Conditionally render the input for "Other" if it is selected */}
              {otherFundRaising === "Other" && (
                <div className="please-specify">
                  <label>Please specify:</label>
                  <input
                    type="text"
                    value={customFundRaising}
                    onChange={(e) => setCustomFundRaising(e.target.value)}
                  />
                </div>
              )}
            </>
          )}

          <label>
            How much is your company’s net income for the most recent year?
          </label>
          <select
            value={netIncome}
            onChange={(e) => setNetIncome(e.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Not Exceeding ₱10M">Not Exceeding ₱10M</option>
            <option value="Over ₱10M to ₱30M">Over ₱10M to ₱30M</option>
            <option value="Over ₱30M to ₱50M">Over ₱30M to ₱50M</option>
            <option value="More than ₱50M">More than ₱50M</option>
          </select>

          <label>
            <span className="required">*</span>Are you interested in talking to
            an IPO advisor?
          </label>
          <select
            value={interestedInIPOAdvisor}
            onChange={(e) => setInterestedInIPOAdvisor(e.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>

          {interestedInIPOAdvisor === "Yes" && (
            <>
              <label className="checkbox-label">
                Please select at least 1 from the following participating IPO
                advisors/event partners:​
              </label>
              <div className="checkbox-group">
                {ipoAdvisorOptions.map((advisor) => (
                  <label key={advisor}>
                    <input
                      type="checkbox"
                      value={advisor}
                      onChange={handleIPOAdvisorsChange}
                      checked={ipoAdvisors.includes(advisor)} // Ensure checked state is linked
                    />{" "}
                    {advisor}
                  </label>
                ))}
              </div>
            </>
          )}

          <label className="comments">
            <span className="required">*</span>
            Please let us know if you have any questions or insights for our
            speakers ahead of the event:
          </label>
          <textarea
            value={questions}
            onChange={(e) => setQuestions(e.target.value)}
          />

          <hr className="vertical-separator" />

          <div className="data-privacy">
            <input
              type="checkbox"
              checked={dataPrivacyAccepted}
              onChange={(e) => setDataPrivacyAccepted(e.target.checked)}
            />
            <p>
              By registering to “The Road to IPO 2024” and by providing the
              requested information, you are giving PSE and its event partners
              your consent to collect, use, store, process, and disclose your
              personal data for purposes of your participation in the said event
              and you also agree to PSE sending you marketing materials about
              future PSE events. The PSE will retain your personal data for a
              period of one (1) year reckoned from the end of this PSE event.
              Kindly visit our{" "}
              <a
                href="https://www.pse.com.ph/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>{" "}
              page.
            </p>
          </div>

          <button
            type="submit"
            onClick={(e) => setShowModal(true)}
            disabled={!isFormValid()} // Disable button if form is not valid
          >
            REGISTER
          </button>

          {/* Pass showModal state and setShowModal to the Modal component */}
          {/* <RegistrationModal showModal={showModal} setShowModal={setShowModal}>
                                <h2>Modal Content</h2>
                                <p>This is the content inside the modal.</p>
                                <button onClick={() => setShowModal(false)}>Close Modal</button>
                            </RegistrationModal> */}

          <RegistrationModal
            showModal={showModal}
            closeModal={closeModal} // Pass closeModal as prop
            apiResponse={apiResponse} // Pass response as prop
            isLoading={isLoading} // Pass loading state as prop
          />
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
