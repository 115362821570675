import React from 'react';

const RegistrationModal = ({ showModal, closeModal, apiResponse, isLoading }) => {
    if (!showModal) return null; // Do not render if showModal is false

    const loadingMessage = (
        <>
            <h2>Submitting Your Registration...</h2>
            <p>Please wait while we process your registration.</p>
        </>
    );

    const successMessage = (
        <>
            <h2>Your Registration Has Been Received</h2>
            <hr className="vertical-separator" />
            <div className='modal-success'>
              <p className='p-success'>We've received your registration for the Philippine Stock Exchange's The Road to IPO 2024 forum, happening on October 22.</p>
              <h3>Exclusive Entry Ticket</h3>
              <p>You will receive a separate email confirmation regarding your participation status.</p>
              <h3>Questions? We're Here to Help!</h3>
              <p>Should you have any inquiries or require further information, please email us at leap@pse.com.ph.</p>
              <p className='p-thanks'>Thank you for your interest in joining The Road to IPO 2024!</p>
            </div>
        </>
    );

    const errorMessage = (
        <>
            <h2 className='error'>Error</h2>
            <h3>{apiResponse?.message || 'An unexpected error occurred. Please try again later.'}</h3>
        </>
    );

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {isLoading ? loadingMessage : (apiResponse?.status === 'success' ? successMessage : errorMessage)}
                {!isLoading && <button className='modal-button' onClick={closeModal}>CLOSE</button>}
            </div>
        </div>
    );
};

export default RegistrationModal;
