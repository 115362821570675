import React from 'react'

import PSE from '../images/eventPartners/PSE_Logo.png'
import SEC from '../images/eventPartners/SEC_LOGO_(1).png'

const Nav = () => {
  return (
    <div className='nav'>
        <div className='navbar'>
            <img src={PSE} alt='PSE' id='pse_logo'/>
            <img src={SEC} alt='SEC' id='sec_logo'/>
        </div>
    </div>
  )
}

export default Nav