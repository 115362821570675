import React from "react";

import BDOBlue from "../images/sponsors/BDO Capital Logo - Blue.png";
import ChinaBank from "../images/sponsors/Chinabank Logo.jpg";
import MetroBank from '../images/sponsors/FMICMG Blue.png'
import ICCP from '../images/sponsors/ICCP logo short gray.png'
import PNB from '../images/sponsors/PNB Capital - Logo.jpg'
import SBCapital from '../images/sponsors/SB_Capital.jpg'

const ParticipatingCompanies = () => {
  return (
    <div className="participating-companies">
      <h2>Sponsors</h2>

      <div className="sponsor-container">
          <div className="sponsor-container-1">
            <img src={BDOBlue} alt="BDO" id="bdo" />
            <img src={ChinaBank} alt="ChinaBank" id="chinabank" />
            <img src={MetroBank} alt="MetroBank" id="metrobank" />
          </div>
          <div className="sponsor-container-2">
          <img src={ICCP} alt="ICCP" id="iccp" />
          <img src={PNB} alt="PNB" id="pnb" />
          <img src={SBCapital} alt="SB Capital" id="sbc" />
          </div>
      </div>

    </div>
  );
};

export default ParticipatingCompanies;
