import React from "react";

import EventList from "./EventList";

import RamonMonzon from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Ramon S. Monzon.jpg';
import MiguelAgarao from '../images/speakers/Speaker Collaterals/Capitalizing on Market Opportunities Outlook and Investment Insights/Miguel Agarao.jpg';
import CarolMills from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Carol T. Mills.png';
import MartinZamora from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Martin Zamora.png';
import NatalieKwong from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Natalie Kwong.png';
import OliverTan from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Oliver Tan.png';
import HazelTanedo from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Hazel Tanedo.png';
import JoeyCrisol from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Jose B. Crisol.png';
import MarkCanizares from '../images/speakers/Speaker Collaterals/Igniting Investor Interest Crafting a Compelling Narrative for Your Company/Mark Canizares/Mark Canizares - Photo.jpg';
import MannyOcampo from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/J. Mariano Ocampo.png';
import RoelRefran from '../images/speakers/Speaker Collaterals/Roel Refran.jpeg';
import RyanTapia from '../images/speakers/Speaker Collaterals/Ryan Tapia.jpg';
import RossFergusson from '../images/speakers/Speaker Collaterals/OneDrive_1_9-27-2024/Ross Ferguson.png';

const Agenda = () => {
  const scheduleData = [
    {
      time: "9:00 AM – 9:20 AM",
      duration: "(20 minutes)",
      segment: "Registration and Networking",
      speakers: [],
    },
    {
      time: "9:20 AM – 9:25 AM",
      duration: "(5 minutes)",
      segment: "Opening Remarks",
      speakers: [
        {
          name: "Ramon S. Monzon",
          role: "President and Chief Executive Officer, The Philippine Stock Exchange, Inc.",
          image: RamonMonzon,
        },
      ],
    },
    {
      time: "9:25 AM – 9:30 AM",
      duration: "(5 minutes)",
      segment: "Keynote Message",
      speakers: [
        {
          name: "Hon. Emilio B. Aquino",
          role: "Chairperson, Securities and Exchange Commission",
          image: "https://star.pse.com.ph/images/agenda/SpeakerIcon.png",
        },
      ],
    },
    {
      time: "9:30 AM – 10:00 AM",
      duration: "(15 minutes presentation and 15 minutes Q&A)",
      segment:
        "Capitalizing on Market Opportunities: Outlook and Investment Insights",
      speakers: [
        {
          name: "Miguel A. Agarao",
          role: "Vice President, Philequity Management, Inc.",
          image: MiguelAgarao,
        },
      ],
    },
    {
      time: "10:00 AM – 10:50 AM",
      duration:
        "(5 mins stage set-up, 30 mins panel discussion, 15 minutes Q&A)",
      segment: "Beyond the Ticker: Lessons Learned from Successful IPOs",
      speakers: [
        {
          name: "Ryan L. Tapia",
          role: "President, Chinabank Capital Corporation",
          image: RyanTapia,
        },
        {
          name: "Carol T. Mills",
          role: "President and Chief Executive Officer, Ayala Land Offices",
          image: CarolMills,
        },
        {
          name: "Martin Antonio G. Zamora",
          role: "President and Chief Executive Officer, Nickel Asia Corporation",
          image: MartinZamora,
        },
      ],
    },
    {
      time: "10:50 AM – 11:25 AM",
      duration: "(20 minutes presentation and 15 minutes panel discussion/Q/A)",
      segment:
        "Empowering Sustainable Development: An Introduction to Mobilist",
      speakers: [
        {
          name: "Roel A. Refran",
          role: "Chief Operating Officer, The Philippine Stock Exchange, Inc.",
          image: RoelRefran,
        },
        {
          name: "Ross Ferguson",
          role: "Senior Private Sector Adviser, UK Foreign, Commonwealth and Development Office",
          image: RossFergusson,
        },
        {
          name: "Natalie Kwong",
          role: "Capital Advisory Associate, Palladium",
          image: NatalieKwong,
        },
        {
          name: "Oliver Y. Tan",
          role: "President and Chief Executive Officer, Citicore Renewable Energy Corporation",
          image: OliverTan,
        },
      ],
    },
    {
      time: "11:25 AM – 12:05 PM",
      duration:
        "(5 minutes stage set-up, 20 minutes presentation and 15 minutes panel discussion/Q&A)",
      segment:
        "Igniting Investor Interest: Crafting a Compelling Narrative for Your Company",
      speakers: [
        {
          name: "J. Mariano Ocampo",
          role: "Managing Director, Investment & Capital Corporation of the Philippines",
          image: MannyOcampo,
        },
        {
          name: "Hazel Tañedo",
          role: "Head of Research, AB Capital Securities",
          image: HazelTanedo,
        },
        {
          name: "Mark Alan Cañizares",
          role: "Head of Equities, Manulife Investment Management and Trust Corporation",
          image: MarkCanizares,
        },
        {
          name: "Jose B. Crisol Jr.",
          role: "Head of Investment Relations, GT Capital Holdings, Inc.",
          image: JoeyCrisol,
        },
      ],
    },
    {
      time: "12:05 PM - 12:15 PM",
      duration: "(10 minutes)",
      segment: "Embracing the IPO Mindset Video Playback",
      speakers: []
    },
    {
      time: "12:15 PM - 2:00 PM",
      duration: "(1 hour 45 minutes)",
      segment: "Lunch, Exhibition Booths, and Networking",
      speakers: []
    },
  ];

  return (
    <div className="agenda">
      <div className="agenda-container">
        <h2>Agenda</h2>

        <div className="schedule-list">
          {scheduleData.map((item, index) => (
            <EventList
              key={index}
              time={item.time}
              duration={item.duration}
              segment={item.segment}
              speakers={item.speakers}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Agenda;
