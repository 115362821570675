import { useRef } from "react"

import Hero from "../components/Hero"
import About from "../components/About"
import Agenda from "../components/Agenda"
import ParticipatingCompanies from "../components/ParticipatingCompanies"
import RegistrationForm from "../components/RegistrationForm"

const RegistrationPage = () => {

  const formRef = useRef(null);

   // Function to handle scrolling to specific section
   const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };


  return (
    <div className="reg-page">
        {/* <Hero /> */}

        <Hero scrollToSection={scrollToSection} refs={{ formRef }} />

        <About />
        <Agenda />
        <ParticipatingCompanies />
        {/* <RegistrationForm /> */}

        <div ref={formRef}><RegistrationForm /></div>
    </div>
  )
}

export default RegistrationPage