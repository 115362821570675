import React from 'react'

const About = () => {
  return (
    <div className='about'>
        <div className='about-container'>
            <h2>About the Event</h2>
            <p id='p1'>
                "The Road to IPO 2024" is the Philippine Stock Exchange's annual IPO forum, in collaboration with the Securities and Exchange Commission and co-hosted by the Foreign, Commonwealth & Development Office through MOBILIST.
            </p>
            <p id='p2'>
                Going public is a transformative step for companies aiming to raise significant capital to fuel their growth ambitions. <br />Join us for a day of engaging presentations from industry leaders and market experts, who will share their insights and strategies to equip business owners for a successful IPO and uncover new opportunities for their companies.
            </p>
        </div>
    </div>
  )
}

export default About